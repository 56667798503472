<template>
  <v-container>
    <h1
      v-intersect="{
        handler: onIntersect,
        options: {
          threshold: [0.95],
        },
      }"
      class="d-flex justify-center"
      style="font-family: 'Garamond Pro'; font-size: 48px"
      @click="pushNotifications()"
    >
      {{ doc.title }}
    </h1>
    <v-sheet v-for="(section, i) in doc.content" v-bind:key="i" class="ml-4">
      <div>
        <v-checkbox
          v-if="section.checkbox"
          dense
          style="float: left; margin-top: -2px"
        ></v-checkbox>
        <div v-if="section.type === 'header'">
          <v-divider class="my-4"></v-divider>
          <h1 style="font-family: 'Garamond Pro'" class="mt-4">
            {{ section.title }}
          </h1>
        </div>
        <h3 v-else style="font-family: 'Garamond Pro'" class="mt-4">
          {{ section.title }}
        </h3>
        <div v-if="isEditing === i">
          <v-text-field label="Title" v-model="editTitle"></v-text-field>
          <v-textarea
            label="Content"
            auto-grow
            min-height="50"
            v-model="editContent"
          ></v-textarea>
          <v-card-actions>
            <v-spacer></v-spacer>
            <div class="mr-4">
              {{ formatDate(section.lastUpdated.seconds) }}
            </div>
            <v-btn
              v-if="editMode"
              fab
              small
              bottom
              right
              :color="section.checkbox ? 'blue' : 'grey darken-2'"
              @click="section.checkbox = !section.checkbox"
            >
              <v-icon color="white">{{
                section.checkbox
                  ? "mdi-checkbox-marked-outline"
                  : "mdi-checkbox-blank-outline"
              }}</v-icon>
            </v-btn>
            <v-btn
              v-if="editMode"
              fab
              small
              bottom
              right
              color="green"
              @click="isFinishedEditingContent(i)"
            >
              <v-icon color="white">mdi-check</v-icon>
            </v-btn>
            <v-btn
              v-if="editMode"
              fab
              small
              bottom
              right
              color="yellow darken-2"
              @click="canceledEditingContent(i)"
            >
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
            <v-btn
              v-if="editMode"
              fab
              small
              bottom
              right
              color="red"
              @click="deleteContent(i)"
            >
              <v-icon color="white">mdi-delete</v-icon>
            </v-btn>
          </v-card-actions>
        </div>

        <span v-if="section.content !== '' && isEditing !== i">
          <div
            class="font-weight-light ml-4"
            style="font-family: 'Garamond Pro'"
          >
            {{ section.content }}
          </div>
        </span>
        <v-card-actions v-if="editMode && isEditing !== i">
          <v-spacer></v-spacer>
          <div class="mr-4">{{ formatDate(section.lastUpdated.seconds) }}</div>
          <v-btn
            fab
            small
            bottom
            right
            color="purple darken-4"
            @click="isEditingContent(i)"
          >
            <v-icon color="white">mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="editMode"
            fab
            small
            bottom
            right
            :disabled="i === 0"
            color="orange"
            @click="moveItemUp(i)"
          >
            <v-icon color="white">mdi-chevron-up</v-icon>
          </v-btn>
          <v-btn
            v-if="editMode"
            fab
            small
            bottom
            right
            :disabled="i === doc.content.length - 1"
            color="orange"
            @click="moveItemDown(i)"
          >
            <v-icon color="white">mdi-chevron-down</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
    </v-sheet>
    <v-spacer></v-spacer>
    <v-container>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn
          v-if="editMode"
          fab
          color="green"
          class="my-6 mr-3"
          @click="addContent('header')"
        >
          <v-icon color="white">mdi-playlist-plus</v-icon>
        </v-btn>
        <v-btn
          v-if="editMode"
          fab
          color="green"
          class="my-6 ml-3"
          @click="addContent('content')"
        >
          <v-icon color="white">mdi-plus</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>
    <v-spacer class="pb-6 mb-16"></v-spacer>
  </v-container>
</template>
<script>
import moment from "moment";
import {
  firebaseApp,
  Timestamp,
  askForPermissionToReceiveNotifications,
} from "../firebase/init";
import "firebase/firestore";

export default {
  name: "Docs",
  data: function () {
    return {
      isIntersecting: true,
      isEditing: null,
      editContent: null,
      editTitle: null,
      editCheckbox: false,
    };
  },
  computed: {
    doc() {
      if (this.$store.state.docs.length > 0)
        return this.$store.state.docs[this.selectedDoc.doc];
      return [];
    },
    editMode() {
      return this.$store.state.editDoc;
    },
    selectedDoc() {
      return this.$store.state.docsDrawer;
    },
  },
  methods: {
    onIntersect(entries, observer) {
      observer;
      this.isIntersecting = entries[0].isIntersecting;
    },
    isEditingContent(id) {
      this.editContent = this.doc.content[id].content;
      this.editTitle = this.doc.content[id].title;
      this.editCheckbox = this.doc.content[id].checkbox;
      this.isEditing = id;
    },
    canceledEditingContent() {
      this.isEditing = null;
      this.editContent = null;
      this.editTitle = null;
      this.editCheckbox = null;
    },
    isFinishedEditingContent(id) {
      let content = [...this.doc.content];
      content[id].lastUpdated = new Date();
      console.log(content[id].lastUpdated);
      content[id].content = this.editContent;
      content[id].title = this.editTitle;
      this.updateContent(content);
      this.editContent = null;
      this.editTitle = null;
      this.editCheckbox = false;
      this.isEditing = null;
    },
    moveItemUp(id) {
      let content = [...this.doc.content];
      let temp = { ...content[id - 1] };
      content[id - 1] = content[id];
      content[id] = temp;
      this.updateContent(content);
    },
    moveItemDown(id) {
      let content = [...this.doc.content];
      let temp = { ...content[id + 1] };
      content[id + 1] = content[id];
      content[id] = temp;
      this.updateContent(content);
    },
    addContent(type) {
      let content = [...this.doc.content];
      content.push({
        checkbox: false,
        type: type,
        lastUpdated: Timestamp.fromDate(new Date()),
        title: "",
        content: "",
      });
      this.updateContent(content);
      this.isEditing = content.length - 1;
    },
    deleteContent(id) {
      this.isEditing = null;
      this.doc.content.splice(id, 1);
      this.editContent = null;
      this.editTitle = null;
    },
    updateContent(content) {
      firebaseApp
        .firestore()
        .collection("docs")
        .doc(this.doc.key)
        .update({ content: content });
    },
    pushNotifications() {
      console.log("Hello Notification Request");
      askForPermissionToReceiveNotifications;
    },
    formatDate(date) {
      return moment(date * 1000).format("lll");
    },
  },
};
</script>
